<template>
  <div v-loading="is_panding" class="shilut-db">
    <div class="shilut-db-tools">
        <el-input  style="width:20%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
        <el-select style="margin-right:5px;" filterable v-model="selected_department" class="m-2" placeholder="מחלקה" size="large">
            <el-option
            v-for="cat in categories"
            :key="cat"
            :value="cat"
            />
        </el-select>
        <el-select style="margin-right:5px;" filterable v-model="selected_supplier" class="m-2" placeholder="ספק" size="large">
            <el-option
            v-for="sup in suppliers"
            :key="sup"
            :value="sup"
            />
        </el-select>
   
        <div class="back">
            <i @click="$emit('back')" class="material-icons">arrow_back</i>
        </div>
    </div>
    <div class="shilut-db-content">
        <table>
            <tr>
                <th style="cursor:pointer;" @click="handleSortTable('ברקוד', !selectedSorted.order)">ברקוד</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                <th style="cursor:pointer;" @click="handleSortTable('עברית', !selectedSorted.order)">שם פריט עברית</th>
                <th style="cursor:pointer;" @click="handleSortTable('ספק', !selectedSorted.order)">ספק</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחיר', !selectedSorted.order)">מחיר</th>
                <th>פעולות</th>
            </tr>
            <h1 v-if="sorted_list.length == 0" style="color:#fff;">לא קיימות רשומות</h1>
            <template v-for="(item,index) in sorted_list" :key="`${item.barcode}${index}`">
                <tr @click="print_line_for_check(item)">
                   <td>{{item.barcode}}</td> 
                   <td>{{item.Department}}</td> 
                   <td>{{item.Name}}</td> 
                   <td>{{item.SupplierName}}</td> 
                   <td>{{item.price}} &#8362;</td> 
                   <td>
                     <el-button  @click="handle_choose(item)" type="success">בחר</el-button>
                   </td> 
                </tr>
            </template>   
        </table>
    </div>
    <div class="shilut-db-pagination">
        <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="filter_by_search.length" 
        />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import {items,categories,suppliers,is_catalog_loaded,get_non_promos_items} from './components/Deals/Scripts/script'
import {slide_pop_error} from '../../../Methods/Msgs'


export default {
    emits:['item_choosen','back'],
    components:{},
    setup(_,{emit}){
        
        const is_panding = ref(false)

        const print_line_for_check = (item) => {
            console.log(item);
        }

        const selected_supplier = ref('')
        const selected_department = ref('')
        const search = ref('')
        const selected_item = ref(null)
        
        const pagination = ref({
            current_page:1,
            number_per_page:30,
        })


        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };

        const selectedSorted = ref({
            name: "",
            order: false
        });

        const slice_list = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page -1 ) * pagination.value.number_per_page,
            ((pagination.value.current_page-1) * pagination.value.number_per_page) + pagination.value.number_per_page)
        }))

        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "")return slice_list.value;
            if (selectedSorted.value.name == "ברקוד") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.barcode > b.barcode
                        ? 1
                        : b.barcode  > a.barcode
                        ? -1
                        : 0;
                    }
                    return a.barcode > b.barcode
                    ? -1
                    : b.barcode  > a.barcode
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.Department > b.Department
                        ? 1
                        : b.Department  > a.Department
                        ? -1
                        : 0;
                    }
                    return a.Department > b.Department
                    ? -1
                    : b.Department  > a.Department
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "עברית") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.Name > b.Name
                        ? 1
                        : b.Name  > a.Name
                        ? -1
                        : 0;
                    }
                    return a.Name > b.Name
                    ? -1
                    : b.Name  > a.Name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "ספק") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.SupplierName > b.SupplierName
                        ? 1
                        : b.SupplierName  > a.SupplierName
                        ? -1
                        : 0;
                    }
                    return a.SupplierName > b.SupplierName
                    ? -1
                    : b.SupplierName  > a.SupplierName
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחיר") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  Number(a.price) > Number(b.price)
                        ? 1
                        : Number(b.price)  > Number(a.price)
                        ? -1
                        : 0;
                    }
                    return Number(a.price) > Number(b.price)
                    ? -1
                    : Number(b.price)  > Number(a.price)
                    ? 1
                    : 0;
                });
            }
        }));
        
        const filter_by_search = ref(computed(() => {
            if(!search.value) return filter_by_supplier.value
            return filter_by_supplier.value.filter(item=>{
                if(item.barcode?.includes(search.value)) return item
                if(item.Name?.includes(search.value)) return item
                if(item.price == search.value) return item
                if(item.SupplierName?.includes(search.value)) return item
                if(item.Department?.includes(search.value)) return item
            })
        }))

        const filter_by_supplier = ref(computed(()=>{
            if(!selected_supplier.value || selected_supplier.value=='הכל') return filter_by_department.value
            return filter_by_department.value.filter(item=>item.SupplierName.includes(selected_supplier.value))
        }))

        const filter_by_department = ref(computed(()=>{
            if(selected_department.value== '' || selected_department.value == 'הכל')return items.value
            return items.value.filter(item=>item.Department == selected_department.value)
        }))
        
        const handle_choose = (item) => {
            emit('item_choosen',item)
        }

        const init = async() => {
            try{
                if(!is_catalog_loaded.value){
                    is_panding.value = true
                    await get_non_promos_items()
                }
            }catch(err){
                console.error(err.message);
                slide_pop_error(err.message)
            }finally{
                is_panding.value = false
            }
        }

        init()

        

        return{
            print_line_for_check,
            handleSortTable,
            handle_choose,
            search,
            items,
            pagination,
            filter_by_search,
            selectedSorted,
            sorted_list,
            slice_list,
            selected_item,
            selected_department,
            categories,
            suppliers,
            selected_supplier,
            filter_by_supplier,
            is_panding
        }
    }
}
</script>

<style scoped>
    .shilut-db{
        margin: 0 auto;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        padding: 5px;
        color: #fff;
    }
    .shilut-db-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .back{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--danger);
        color: #fff;
        cursor: pointer;
        margin-right: auto;
    }
    .shilut-db-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow: hidden;
        overflow-y: auto;
    }
    .shilut-db-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }
    .icon{
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
        
    }
</style>